@use "../../variables" as var;

.ask,
.bid {
  td {
    padding: 10px 10px;
  }
}

.ask {
  color: var.$ask-color;
}

.bid {
  color: var.$bid-color;
}

.container {
  width: 100%;
  height: 100px;
  background-color: lightgray;
  position: relative;
}

.dynamic-div {
  height: 100%;
  background-color: green;
  width: 40%; /* Initial width set to 40% */
  transition: width 0.3s ease;
}
