@use '../../variables'as var;

.container {
    width: 240px;
    min-height: 100vh;

    padding: 20px 10px 40px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    border-right: 1px solid rgba(0, 0, 0, 0.429);
}

.title {
    @include var.h2-text;
}