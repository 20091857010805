@use '../../variables'as var;

.header {
    width: 400px;

    align-items: end;
    gap: 20px;

    button {
        padding: 0 30px;
    }
}

.title {
    @include var.h2-text;
}

.formContainer {
    width: 100%;
}

.createAccountInputField {
    width: 400px;
}