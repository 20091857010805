@use "../../variables" as var;

.form {
  width: 100%;
}

.select {
  width: 260px !important;
}

.pairSelect {
  width: 320px !important;
}

.green {
  background-color: var.$green;
}

.red {
  background-color: var.$red;
}

.strategiesContainer {
  // width="100%" flexDirection="column" maxH="700px"
  width: 100%;
  flex-direction: column;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
