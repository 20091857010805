.input {
  width: 270px;
  & > div > div {
    width: 170px;
    & > input {
      padding-right: 0;
      display: inline-block;
    }
  }
}

.submitBtn {
  width: 200px;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.pumpInfoSection {
  width: calc(100% - 40px);
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 10px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pumpInput {
  width: auto;
}

.isRed {
  color: red;
}
