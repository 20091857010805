.green {
  background-color: #27a69a;
}
.red {
  background-color: lightcoral;
}

.clickable {
  text-decoration: underline dashed;
  cursor: pointer;
  color: #1e90ff;
}
