.container {
    width: auto;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.label {
    width: auto;
    text-wrap: nowrap;
}

.select {
    height: 40px;
}